// 👇 It's important to not import @valu/react-valu-search directly here but
// only the subpackage `lazy`.  This ensures the main entry bundle won't get
// bloated by RVS.
import { LazyValuSearch, select } from "@valu/react-valu-search/lazy";

const loader = new LazyValuSearch({
    // import the file exporting the ValuSearch instance as the default export
    load: () => import("./search-ui"),
});

/**
 * INSTRUCTIONS:
 * This loader.tsx and valu-search.tsx are enough to connect valu search
 * to a button that open search. RVS will open in full screen modal.
 *
 * If you need to open search in partial modal, or customize the search,
 * check the docs in README.
 */

loader.init(() => {
    const input = document.querySelector("#valu-search-fs-input");
    const mobButton = select("#search-toggle-mobile", HTMLButtonElement);
    const desktopButton = select("#search-toggle-desktop", HTMLButtonElement);

    if (input instanceof HTMLInputElement) {
        input.addEventListener("focus", () => {
            loader.load();
        });
    }

    mobButton.addEventListener("click", () => {
            loader.activate();
        },
        false,
    );

    desktopButton.addEventListener("click", () => {
            loader.activate();
        },
        false,
    );

    return (vs) => {
        if (input instanceof HTMLInputElement) {
            vs.bindInputAsOpener(input);
        }
        
        vs.initModal();
    };
});
